<template>
  <b-field v-if="userId" label="User controls">
    <b-select
      v-model="selected"
      :loading="processing"
      :disabled="processing"
      placeholder="Select user action"
      expanded
    >
      <optgroup label="User controls:">
        <option :value="[viewUser]">View user profile</option>
        <option :value="[viewLogs, { userId }]">View user logs</option>
        <option :value="[viewNotes, { userId }]">View user notes</option>
        <option :value="[addSite]">Add new site</option>
      </optgroup>
    </b-select>
  </b-field>
</template>

<script>
export default {
  name: "StaffUserControls",
  props: {
    userId: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      processing: false,
      selected: null
    };
  },
  watch: {
    async selected(action) {
      if (action === null) return;
      this.processing = true;
      if (this.$_.isFunction(action[0])) await action[0](action[1]);
      this.$nextTick(() => {
        this.selected = null;
        this.processing = false;
      });
    }
  },
  methods: {
    viewLogs(params) {
      this.$modal.open({
        component: () => import("@shared/logs/_logsModal"),
        parent: this,
        width: 720,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: this.$_.merge({}, { adminContext: true }, params)
      });
    },
    viewNotes(params) {
      this.$modal.open({
        component: () => import("@shared/user/_userNotesModal"),
        parent: this,
        width: 720,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: this.$_.merge({}, { adminContext: true }, params)
      });
    },
    addSite() {
      const modal = this.$modal.open({
        component: () => import("@shared/sites/_addSiteModal"),
        parent: this,
        width: 540,
        hasModalCard: true,
        canCancel: [],
        events: {
          success: siteId => {
            modal.close();
            this.$router.push(`/sites/${siteId}`);
          }
        },
        props: {
          parent: this,
          userId: this.userId
        }
      });
    },
    viewUser() {
      this.$router.push({ path: `/admin/users/${this.userId}` });
    }
  }
};
</script>
